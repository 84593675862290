import _ from "lodash"

const randomId = _.uniqueId

const NAVIGATION_DATA = [
  // {
  //   id: randomId(),
  //   href: "/",
  //   name: "Homes",
  //   type: "dropdown",
  //   children: [
  //     { id: randomId(), href: "/", name: "Home page 1" },
  //     { id: randomId(), href: "/home-2", name: "Home page 2" },
  //     { id: randomId(), href: "/home-3", name: "Home page 3" },
  //     { id: randomId(), href: "/home-4", name: "Home page 4" },
  //     { id: randomId(), href: "/home-6", name: "Home page 5" },
  //     { id: randomId(), href: "/", name: "Header style 1", isNew: true },
  //     { id: randomId(), href: "/home-2", name: "Header style 2", isNew: true },
  //     { id: randomId(), href: "/home-3", name: "Header style 3" },
  //   ],
  // },

  // single pages ----------------
  // {
  //   id: randomId(),
  //   href: "/single/demo-slug",
  //   name: "Singles",
  //   type: "dropdown",
  //   children: [
  //     {
  //       id: randomId(),
  //       href: "/single/demo-slug",
  //       name: "Single page 1",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/single-2/demo-slug",
  //       name: "Single page 2",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/single-3/demo-slug",
  //       name: "Single page 3",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/single-4/demo-slug",
  //       name: "Single page 4",
  //     },

  //     {
  //       id: randomId(),
  //       href: "/single-audio/demo-slug",
  //       name: "Single Audio",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/single-video/demo-slug",
  //       name: "Single Video",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/single-gallery/demo-slug",
  //       name: "Single Gallery",
  //       isNew: true,
  //     },
  //   ],
  // },

  //
  {
    id: randomId(),
    href: "/",
    name: "Početna",
  },

  {
    id: randomId(),
    href: "/istorija",
    name: "Istorija",
  },

  {
    id: randomId(),
    href: "/kontakt",
    name: "Kontaktirajte nas",
  },
]

const RECENT_POSTS = [
  {
    id: 1,
    title: "Boost your conversion rate",
    href: "/single-gallery/demo-slug",
    date: "Mar 16, 2023",
    datetime: "2023-03-16",
    category: { title: "Marketing", href: "/archive/demo-slug" },
    imageUrl:
      "https://images.unsplash.com/photo-1678720175173-f57e293022e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0MjJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    description:
      "Et et dolore officia quis nostrud esse aute cillum irure do esse. Eiusmod ad deserunt cupidatat est magna Lorem.",
  },
  {
    id: 2,
    title: "How to use search engine optimization to drive sales",
    href: "/single-gallery/demo-slug",
    date: "Mar 10, 2023",
    datetime: "2023-03-10",
    category: { title: "Sales", href: "/archive/demo-slug" },
    imageUrl:
      "https://images.unsplash.com/photo-1678846912726-667eda5a850f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyODh8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    description: "Optio cum necessitatibus dolor voluptatum provident commodi et.",
  },
]

export { NAVIGATION_DATA, RECENT_POSTS }
