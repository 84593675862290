import axios from "axios"
import { ACCESS_TOKEN } from "constants/auth"
import { BASE_URL } from "constants/config"

export const axiosBase = axios.create({
  baseURL: BASE_URL.DEVELOPMENT,
  headers: {
    "Content-Type": "application/json",
    "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    Authorization: localStorage.getItem(ACCESS_TOKEN)
      ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      : null,
  },
})
