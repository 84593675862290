function FeaturedIcon({
  className = "",
  isVideo = false,
  onClick,
  wrapSize = "w-11 h-11",
  iconSize = "w-6 h-6",
}) {
  const renderMediaIcon = () => {
    if (isVideo) {
      return (
        <svg
          className={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.13 7.9799C20.96 10.1899 20.96 13.8099 17.13 16.0199L14.04 17.7999L10.95 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L13.21 5.7199"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    }

    return null
  }

  return (
    <div
      className={`nc-PostTypeFeaturedIcon ${className}`}
      data-nc-id="PostTypeFeaturedIcon"
      onClick={onClick}
    >
      {isVideo && (
        <span
          className={`bg-neutral-900 bg-opacity-60 rounded-full flex  items-center justify-center text-xl text-white border border-white ${wrapSize}`}
        >
          {renderMediaIcon()}
        </span>
      )}
    </div>
  )
}

export default FeaturedIcon
