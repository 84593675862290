import useAuthApi from "api/aAuth"
import Footer from "components/Footer/Footer"
import Header from "components/Header/Header"
import ContactUsPage from "pages/ContactUs/ContactUsPage"
import DashboardPostListPage from "pages/Dashboard/DashboardPostListPage"
import DashboardSaveArticle from "pages/Dashboard/DashboardSaveArticle"
import HistoryPage from "pages/History/HistoryPage"
import HomePage from "pages/Home/HomePage"
import NotFoundPage from "pages/NotFound"
import SignInPage from "pages/SignIn/SignInPage"
import SignUpPage from "pages/SignUp/SignUpPage"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { setUser as setUserStore } from "store/authSlice"

function App() {
  const { user } = useSelector(x => x.auth)
  const dispatch = useDispatch()
  const authApi = useAuthApi()

  useEffect(() => {
    authApi
      .checkToken()
      .then(x => {
        const userResponse = x.data
        dispatch(setUserStore(userResponse))
      })
      .catch(x => {
        console.log("Check token error: ", x)
      })
  }, [])

  const privatePageEnterIf = user

  const signInAndUpEnterIf = !user

  return (
    <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
      <BrowserRouter>
        <div className="App">
          <Header />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/istorija" element={<HistoryPage />} />
            <Route path="/kontakt" element={<ContactUsPage />} />
            <Route
              path="/dashboard/cuvanje-posta"
              element={
                <PrivateRoute enterIf={privatePageEnterIf}>
                  <DashboardSaveArticle />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/postovi"
              element={
                <PrivateRoute enterIf={privatePageEnterIf}>
                  <DashboardPostListPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/prijava"
              element={
                <CheckAreYouLoggedIn enterIf={signInAndUpEnterIf}>
                  <SignInPage />
                </CheckAreYouLoggedIn>
              }
            />
            <Route
              path="/verify-email"
              element={
                <CheckAreYouLoggedIn enterIf={signInAndUpEnterIf}>
                  <SignInPage />
                </CheckAreYouLoggedIn>
              }
            />
            <Route
              path="/registracija"
              element={
                <CheckAreYouLoggedIn enterIf={signInAndUpEnterIf}>
                  <SignUpPage />
                </CheckAreYouLoggedIn>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <Footer />
        </div>
      </BrowserRouter>
    </div>
  )
}

function PrivateRoute({ enterIf, children }) {
  const location = useLocation()

  if (!enterIf) {
    return <Navigate to="/prijava" state={{ from: location }} replace />
  }

  return children
}

function CheckAreYouLoggedIn({ enterIf, children }) {
  const location = useLocation()

  if (!enterIf) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

export default App
