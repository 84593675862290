import Card from "components/Card/Card"
import MySlider from "components/Slider/MySlider"

function SliderPosts({ heading, subHeading, className = "", posts, perView = 4 }) {
  let CardComponent = Card

  return (
    <div className={`nc-SectionSliderPosts ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>

      <MySlider
        data={posts}
        renderItem={(item, indx) => <CardComponent key={indx} article={item} />}
        itemPerRow={perView}
      />
    </div>
  )
}

function Heading({
  children,
  desc = "Discover the most outstanding articles in all topics of life. ",
  className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  ...args
}) {
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div className={isCenter ? "text-center w-full max-w-2xl mx-auto " : "max-w-2xl"}>
        <h2 className={`text-2xl md:text-3xl lg:text-4xl font-semibold`} {...args}>
          {children || `Section Heading`}
        </h2>
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
    </div>
  )
}

export default SliderPosts
