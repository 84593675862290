import useArticleApi from "api/aArticle"
import Button from "components/Button/Button"
import Checkbox from "components/Form/Checkbox"
import Input from "components/Form/Input"
import Loading from "components/Loading"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setArticle as setArticleStore } from "store/articleSlice"
import * as Yup from "yup"
import DashboardPage from "./DashboardPage"
import { useTranslation } from "react-i18next"

function DashboardSaveArticle() {
  const dispatch = useDispatch()
  const api = useArticleApi()
  const navigate = useNavigate()
  const { user } = useSelector(x => x.auth)
  const { t } = useTranslation()

  const { articleEdit } = useSelector(x => x.article)
  const [article, setArticle] = useState({
    id: null,
    title: "",
    shortDescription: "",
    externalArticleUrl: "",
    fileIsVideo: false,
    fileUrl: "",
    thumbnailUrl: null,
    userId: user?.id, // Todo
    isActive: true,
  })

  const [loading, setLoading] = useState(false)

  const [responseMessage, setResponseMessage] = useState({
    type: "",
    value: "",
  })

  useEffect(() => {
    if (!articleEdit) {
      return
    }

    setArticle(articleEdit)
  }, [articleEdit])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: article.title,
      shortDescription: article.shortDescription,
      externalArticleUrl: article.externalArticleUrl,
      fileUrl: article.fileUrl,
      thumbnailUrl: article.thumbnailUrl ?? "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Post_Title_Required")),
      shortDescription: Yup.string().required(t("Post_ShortDesc_Required")),
      externalArticleUrl: Yup.string().required(t("Post_ExternalUrl_Required")),
      fileUrl: Yup.string().required(t("Post_FileUrl_Required")),
      thumbnailUrl: article.fileIsVideo
        ? Yup.string().required(t("Post_ThumbnailUrl_Required"))
        : Yup.string(),
    }),
    onSubmit: async values => {
      setResponseMessage({ type: "", value: "" })
      setLoading(true)

      const { title, shortDescription, externalArticleUrl, fileUrl, thumbnailUrl } = values

      try {
        console.log("thumbnail url: ", thumbnailUrl)
        const x = await api.saveArticle({
          id: articleEdit?.id ?? null,
          title,
          shortDescription,
          externalArticleUrl,
          fileIsVideo: article.fileIsVideo,
          fileUrl,
          thumbnailUrl: thumbnailUrl !== "" ? thumbnailUrl : null,
          userId: article.userId, // Todo
          isActive: article.isActive,
        })

        const { message, succeeded } = x

        if (succeeded === true) {
          dispatch(setArticleStore(x.data))
          setResponseMessage({ type: "success", value: t(message) })
        } else {
          setResponseMessage({ type: "error", value: t(message) })
        }
      } catch (error) {
        setResponseMessage({ type: "error", value: t("Server_Error") })
      } finally {
        setLoading(false)
      }
    },
  })

  console.log({ article })

  return (
    <>
      <DashboardPage>
        <div className="space-y-6">
          <form onSubmit={validation.handleSubmit} className="grid grid-cols-1 gap-6">
            <ValidationInput validation={validation} label={t("Post_Title")} property="title" />
            <ValidationInput
              validation={validation}
              label={t("Post_ShortDesc")}
              property="shortDescription"
            />
            <ValidationInput
              validation={validation}
              label={t("Post_ExternalUrl")}
              property="externalArticleUrl"
              placeholder="Npr. https://pogledi.rs"
            />
            <Checkbox
              name="fileIsVideo"
              label={t("Post_IsYouTubeVideo")}
              isRounded
              checked={article.fileIsVideo}
              onChange={() => setArticle(prev => ({ ...prev, fileIsVideo: !prev.fileIsVideo }))}
            />
            <ValidationInput
              validation={validation}
              label={article.fileIsVideo ? t("Post_YouTubeUrl") : t("Post_FileUrl")}
              property="fileUrl"
              placeholder={article.fileIsVideo ? t("Post_YouTubeUrl") : t("Post_FileUrl")}
            />
            {article.fileIsVideo && (
              <ValidationInput
                validation={validation}
                label={t("Post_ThumbnailUrl")}
                property="thumbnailUrl"
                placeholder="Thumbnail slika za YouTube video"
              />
            )}
            <Checkbox
              name="isActive"
              label={t("Post_IsActive")}
              isRounded
              checked={article.isActive}
              onChange={() => setArticle(prev => ({ ...prev, isActive: !prev.isActive }))}
            />

            <Button type="submit" pattern={articleEdit?.id ? "secondary" : "primary"}>
              {loading && <Loading />}
              {articleEdit?.id ? t("Post_Update") : t("Post_Create")}
            </Button>
          </form>

          <div
            className={`mx-auto my-4 px-4 py-2 rounded ${
              responseMessage.type === "success"
                ? "bg-green-100 text-green-800"
                : responseMessage.type === "error"
                ? "bg-red-100 text-red-800"
                : responseMessage.type === "warning"
                ? "bg-yellow-100 text-yellow-800"
                : ""
            }`}
          >
            {responseMessage.value}
          </div>
        </div>
      </DashboardPage>
    </>
  )
}

function ValidationInput({ property, label, validation, type, placeholder = "" }) {
  return (
    <div className="block">
      <label htmlFor={property} className="text-neutral-800 dark:text-neutral-200 text-sm">
        {label}
      </label>

      <Input
        id={property}
        type={type ?? "text"}
        placeholder={placeholder}
        {...validation.getFieldProps(property)}
      />

      {validation.touched[property] && validation.errors[property] ? (
        <div className="text-sm text-red-600">{validation.errors[property]}</div>
      ) : null}
    </div>
  )
}

export default DashboardSaveArticle
