import { axiosBase } from "api/aBase"
import ButtonSecondary from "components/Button/ButtonSecondary"
import Card from "components/Card/Card"
import Image from "components/Image"
import { useEffect, useState } from "react"
import cetniciPng from "assets/images/cetnici_istorija.png"
import { useTranslation } from "react-i18next"

function HistoryPage() {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [numberOfActiveArticles, setNumberOfActiveArticles] = useState(null)
  const { t } = useTranslation()

  const getArticles = () => {
    setLoading(true)

    axiosBase
      .get(
        `/article/articles` +
          (articles?.length > 0 ? `?cursorId=${articles[articles.length - 1].id}` : "")
      )
      .then(x => {
        console.log(x.data.data.items)
        setArticles(prev => [...prev, ...x.data.data.items])
        setHasMore(x.data.data.hasMore)
      })
      .catch(x => {
        console.log(x)
      })
      .finally(x => {
        setLoading(false)
      })
  }

  const getNumberOfActiveArticles = () => {
    axiosBase
      .get("/article/active-number")
      .then(x => {
        setNumberOfActiveArticles(x.data.data.numberOfActiveArticles)
      })
      .catch(x => {
        console.log(x)
      })
  }

  useEffect(() => {
    getNumberOfActiveArticles()
    getArticles()
  }, [])

  return (
    <div className={`nc-PageArchive`}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src={cetniciPng}
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {t("History")}
            </h2>
            {numberOfActiveArticles && (
              <span className="block mt-4 text-neutral-300">
                {numberOfActiveArticles} {t("Articles_Plural")}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {articles.map((x, i) => {
              console.log("x: ", x)
              return <Card key={i} article={x} />
            })}
          </div>

          {/* PAGINATIONS */}
          {hasMore && (
            <div className="flex justify-center flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row">
              <ButtonSecondary
                onClick={() => {
                  getArticles()
                }}
                loading={loading}
              >
                {t("ShowMore")}
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HistoryPage
