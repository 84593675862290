import ButtonSecondary from "components/Button/ButtonSecondary"
import Image from "components/Image"
import typingPng from "assets/images/typing.png"
import BackgroundSection from "components/BackgroundSection"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

function Section4({ className = "", img = typingPng }) {
  return (
    <div className="container relative py-16 my-0 md:my-20">
      <BackgroundSection />
      <ContactUs className={className} img={img} />
    </div>
  )
}

function ContactUs({ className = "", img = typingPng }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div
      className={`nc-ContactUsSection relative flex flex-col lg:flex-row items-center  ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-36 lg:w-2/5">
        <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
          {t("ContactUs_Home_Intro")}
        </span>
        <h2 className="font-semibold text-3xl sm:text-4xl mt-3">{t("ContactUs_Home")}</h2>
        <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          {t("ContactUs_Home_Desc")}
        </span>
        <ButtonSecondary onClick={() => navigate("/kontakt")} className="mt-8">
          {t("ContactUs_Form")}
        </ButtonSecondary>
      </div>
      <div className="flex-grow">
        <Image
          alt="hero"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={img}
          className="rounded-2xl"
        />
      </div>
    </div>
  )
}

export default Section4
