import FeaturedMedia from "components/FeaturedMedia/FeaturedMedia"
import Link from "components/Link"
import { useState } from "react"

function Card({ className = "h-full", size = "normal", article }) {
  const { id, title, shortDescription, externalArticleUrl, fileUrl, fileIsVideo, isActive } =
    article

  const [isHover, setIsHover] = useState(false)

  return (
    <div
      className={`nc-Card2 group relative flex flex-col ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Link href={externalArticleUrl} target="_blank" className="absolute inset-0" />
      <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-9 sm:aspect-w-16 aspect-h-7 sm:aspect-h-9 overflow-hidden z-0">
        <div>
          <FeaturedMedia article={article} isHover={isHover} />
        </div>

        <Link
          href={externalArticleUrl}
          target="_blank"
          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"
        ></Link>
      </div>

      <Link href={externalArticleUrl} target="_blank" className="absolute inset-0" />

      <div className="mt-5 px-4 flex flex-col">
        <div className="space-y-3">
          {/* <PostCardMeta
            className="relative text-sm"
            avatarSize="h-8 w-8 text-sm"
            article={article}
          /> */}

          <h2
            className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${
              size === "large" ? "text-base sm:text-lg md:text-xl" : "text-base"
            }`}
          >
            <Link href={externalArticleUrl} target="_blank" className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
          <span className="block text-neutral-500 dark:text-neutral-400 text-[15px] leading-6 ">
            {shortDescription?.length > 80
              ? `${shortDescription?.slice(0, 80)}...`
              : shortDescription}
          </span>
        </div>
        {/* <div className="my-5 border-t border-neutral-200 dark:border-neutral-700"></div> */}
        <div className="flex items-center justify-between"></div>
      </div>
    </div>
  )
}

function PostCardMeta({
  className = "leading-none text-xs",
  article,
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",
}) {
  const { id, title, shortDescription, externalArticleUrl, fileUrl, fileIsVideo, isActive } =
    article

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <Link
        href={externalArticleUrl}
        target="_blank"
        className="relative flex items-center space-x-2"
      >
        {/* {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        )} */}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {/* *{title} */}
        </span>
      </Link>
      <>
        {/* <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">·</span> */}
        {/* <span className="text-neutral-500 dark:text-neutral-400 font-normal">{"Datum"}</span> */}
      </>
    </div>
  )
}

export default Card
