import Logo from "components/Header/Logo"
import Image from "components/Image"
import {
  discord_48_Png,
  instagram_48_Png,
  tiktok_48_Png,
  youTube_48_Png,
} from "components/SocialMediaIcon/icons"
import { useTranslation } from "react-i18next"

const widgetMenus = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "/", label: "Installation" },
      { href: "/", label: "Release Notes" },
      { href: "/", label: "Upgrade Guide" },
      { href: "/", label: "Browser Support" },
      { href: "/", label: "Editor Support" },
    ],
  },
  // {
  //   id: "1",
  //   title: "Explore",
  //   menus: [
  //     { href: "/", label: "Design features" },
  //     { href: "/", label: "Prototyping" },
  //     { href: "/", label: "Design systems" },
  //     { href: "/", label: "Pricing" },
  //     { href: "/", label: "Customers" },
  //   ],
  // },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "/", label: "Best practices" },
  //     { href: "/", label: "Support" },
  //     { href: "/", label: "Developers" },
  //     { href: "/", label: "Learn design" },
  //     { href: "/", label: "What's new" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "/", label: "Discussion Forums" },
  //     { href: "/", label: "Code of Conduct" },
  //     { href: "/", label: "Community Resources" },
  //     { href: "/", label: "Contributing" },
  //     { href: "/", label: "Concurrent Mode" },
  //   ],
  // },
]

function Footer() {
  const { t } = useTranslation()

  const renderWidgetMenuItem = (menu, index) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">{menu.title}</h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="nc-Footer container relative py-4 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className="px-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center">
        <div className="flex items-center mb-4 lg:mb-0">
          <div className="ttnc-logo inline-block text-primary-6000 flex-shrink-0 mr-3">
            <Logo />
          </div>
          <span className="text-md font-bold">Chetniks Esports</span>
        </div>

        <div className="flex justify-center lg:justify-around w-full lg:w-auto mb-4 lg:mb-0 md:gap-x-4">
          <a
            href={"https://www.instagram.com/chetniksesports/"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Image src={instagram_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">Instagram</span>
          </a>

          <a
            href={"https://discord.com/invite/35euMVw"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={discord_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">Discord</span>
          </a>

          <a
            href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={youTube_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">YouTube</span>
          </a>

          <a
            href={"https://www.tiktok.com/@chetniksesports"}
            target="_blank"
            rel="noreferrer"
            className="flex items-center ml-4 lg:ml-0 gap-x-1"
          >
            <Image src={tiktok_48_Png} width="30" />
            <span className="hidden lg:inline text-sm ml-1">TikTok</span>
          </a>
        </div>

        <div className="text-sm flex items-center space-x-2 mt-4 lg:mt-0">
          <div className="lg:inline">{t("DesignAndDevelopment")}</div>
          <div className="lg:inline">-</div>
          <a
            href="mailto:pavle.vlajic@outlook.com"
            className="text-secondary-6000 hover:text-secondary-500 dark:text-secondary-300 dark:hover:text-secondary-6000 text-link"
          >
            {t("PavleVlajic")}
          </a>
        </div>
      </div>
    </div>

    // <div className="nc-Footer relative py-8 lg:py-10 border-t border-neutral-200 dark:border-neutral-700">
    //   <div className="container flex items-center justify-between">
    //     <div>
    //       <Logo />
    //     </div>
    //     <div className="flex items-center space-x-3 lg:space-x-0 lg:flex-row lg:space-y-2.5 lg:items-center">
    //       <a href="https://www.instagram.com/chetniksesports/" target="_blank" rel="noreferrer">
    //         <Image src={instagram_48_Png} />
    //       </a>
    //       <a href="https://discord.com/invite/35euMVw" target="_blank" rel="noreferrer">
    //         <Image src={discord_48_Png} />
    //       </a>
    //       <a
    //         href="https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         <Image src={youTube_48_Png} />
    //       </a>
    //       <a href="https://www.tiktok.com/@chetniksesports" target="_blank" rel="noreferrer">
    //         <Image src={tiktok_48_Png} />
    //       </a>
    //     </div>
    //     <div>
    //       <p className="text-sm text-neutral-500 dark:text-neutral-400">
    //         Design and developed by: Name LastName
    //       </p>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Footer
